<template>
    <div class="guidePage flex_jc_ac" >
        <h1>槿兔 新纪元 欢迎您</h1>
        <!-- <div style="width: 800px;"><notice sbackground="#F5F7FA" label="register_page_tips"/></div> -->
        <div class="steps flex">
            <div class="step flex_ac">
                <div class="pencil"></div>
                <div class="circle"></div>
                <span>信息填写</span>
            </div>
            <div class="step flex_ac">
                <div class="pencil"></div>
                <div class="circle"></div>
                <span>设置密码</span>
            </div>
            <div class="step flex_ac">
                <div class="pencil"></div>
                <div class="circle"></div>
                <span>注册成功</span>
            </div>
        </div>
        <div class="btnGroup">
            <el-button type="primary" class="buttonClass" @click="login(1)">注册</el-button>
            <el-button type="primary" class="buttonClass" @click="login(2)">登录</el-button>
        </div>
        <div class="copyright">
          <span @click="gongan">{{ webInfo.gongan }}｜</span>
          <span @click="beian">{{ webInfo.beian }}</span> | <span>{{ webInfo.copyright }}</span>
        </div>
    </div>
</template>

<script>
// import notice from '../components/Notice.vue'

export default {
  name: 'guidePage',
  // components: {
  //   notice
  // },
  data() {
    return {
      active: 0,
      webInfo: {}
    }
  },
  created() {
    this.getWebInfo()
  },
  methods: {
    beian() {
      window.open('https://beian.miit.gov.cn')
    },
    gongan() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502402129')
    },
    getWebInfo() {
      this.$api.getWebInfo().then(res => {
        this.webInfo = res
      })
    },
    login(type) {
      if (type === 1) {
        this.$router.push('/register')
        return
      }
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright {
  cursor: pointer;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 36px;
  span:hover {
    color: #0c60e1;
  }
}
.guidePage {
    width: 100%;
    min-height: 100vh;
    flex-direction: column;
}
.steps {
    width: 1200px;
    margin: 100px 0 0 0;
    .pencil {
        width: 300px;
        height: 8px;
        border-radius: 4px;
        background-color: #dcdcdc;
        margin-right: -5px;
    }
    .circle {
        width: 24px;
        height: 24px;
        background-color: #dcdcdc;
        border-radius: 50%;
    }
    span {
        margin-left: 4px;
        color: #dcdcdc;
    }
    .step {
        margin-right: 10px;
        &:first-child {
        .pencil, .circle {
            background-color: #abd155;
        }
        span {
            color: #333;
        }
    }
    }
}
.btnGroup {
    margin-top: 200px;
}

.buttonClass {
  &:last-child {
    margin-left: 60px;
  }
  width: 130px;
  height: 44px;
  padding: 0;
//控制图标icon大小
  /deep/ i {
    font-size: 20px;
  }
//控制字大小
  /deep/  {
    font-size: 20px;
  }
}
</style>
